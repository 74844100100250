import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // State for confirm password
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState(''); // State for password match error
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setPasswordError('');

    // Check if passwords match
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    const payload = { username, email, address, password };

    try {
      const response = await fetch('https://api.ahollygrail.com/my-php-api/auth/register.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
        credentials: 'include', // Ensure credentials are included
      });
      const data = await response.json();
      if (data.status === 'success') {
        navigate('/verify-email', { state: { email } }); // Redirect to verification page
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError('Registration failed. Please try again later.');
      console.error('Error:', err);
    }
  };

  return (
    <div className="d-flex justify-content-center mt-5">
      <div className='shadow-lg card border-0 p-5'>
        <div className='' style={{width: "80vw"}}>
          <h1>Register</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Username:</label>
              <input
                style={{width: "80vw"}}
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Email:</label>
              <input
                style={{width: "80vw"}}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Address:</label>
              <input
                style={{width: "80vw"}}
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Password:</label>
              <input
                style={{width: "80vw"}}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Confirm Password:</label>
              <input
                style={{width: "80vw"}}
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
            <div>
              <input
                type="checkbox"
                id="agreeTerms"
                name="agreeTerms"
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
              />
              <label className="p-2" htmlFor="agreeTerms">
                Agree to terms and conditions
              </label>
            </div>
            <button type="submit" disabled={!agreed}>
              Register
            </button>
          </form>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default Register;
