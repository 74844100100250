import * as React from "react";
import './css/Footer.css';

export const Footer = () => {
    return (
        <footer 
        class="footer text-center text-lg-start p-3">
            <section class="d-flex justify-content-between p-4">
                <div class="me-5">
                <span>Get connected with us on social networks:</span>
                </div>
                <div>
                <a href="" class="text-white me-4">
                    <i class="fab fa-facebook-f"></i>
                </a>
                <a href="" class="text-white me-4">
                    <i class="fab fa-instagram"></i>
                </a>
                </div>
            </section>    
            <section class="">
                <div class=" text-center text-md-start mt-5">
                
                <div class="row mt-3">
                    
                    <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                    
                    <h6 class="text-uppercase fw-bold">A Holly grail</h6>
                    <hr
                        class="mb-4 mt-0 d-inline-block mx-auto"
                        />
                    
                    </div>
                    
                    

                    

                    <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                    
                    <h6 class="text-uppercase fw-bold">Contact</h6>
                    <hr
                        class="mb-4 mt-0 d-inline-block mx-auto"
                        />
                    <p><i class="fas fa-home mr-3"></i> cairo, eg</p>
                    <p><i class="fas fa-envelope mr-3"></i> assemessamm@gmail.com</p>
                    <p><i class="fas fa-phone mr-3"></i> +20 1127875325</p>
                    </div>
                    
                </div>
                
                </div>
            </section>
            </footer>
    )
}
export default Footer
