import React, { useState } from 'react';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';
const Login = ({ onLogin }) => {
  const [email, setemail] = useState('');
  const [password, setPassword] = useState('');
  const { setUserId } = useUser();
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.ahollygrail.com/my-php-api/auth/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        credentials: 'include', // Include credentials like cookies or HTTP authentication
      });
      const data = await response.json();
      if (data.status === 'success') {
        setUserId(data.userId);

        localStorage.setItem('token', data.token); // Store the token


        navigate('/');
      } else {
        console.error('Login failed:', data.message);
        alert("Email or password is wrong, try again.");
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
    
  };

  return (
    <div  className="d-flex justify-content-center mt-5" >
    <div className='shadow-lg card border-0 p-5'>
      <div className='' style={{width: "80vw"}}>
    <form onSubmit={handleLogin}>
      <input
        style={{width: "80vw"}}
        type="text"
        value={email}
        onChange={(e) => setemail(e.target.value)}
        placeholder="email"
        required
      />
      <input
        style={{width: "80vw"}}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        required
      />
      <div style={{width: "80vw"}}className="d-flex justify-content-center mt-2" >
        <button type="submit">Login</button>
      </div>

    </form>
    </div>
      </div>
    </div>
  );
};

export default Login;
