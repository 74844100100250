import React, { useState } from 'react';
import { useUser } from '../../UserContext';
import { useNavigate } from 'react-router-dom';
const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { setUserId } = useUser();
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.ahollygrail.com/my-php-api/admin/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
        credentials: 'include',
      });
      const data = await response.json();
      if (data.status === 'success') {
        // setUserId(data.userId);
        // localStorage.setItem('token',"" ); // Store the token
        localStorage.setItem('adminID', data.userId); // Store the token
        localStorage.setItem('tokenAdmin', data.token); // Store the token
        // onLogin(data.user); // Call the onLogin    with user data
        console.log(data.token);
        navigate('/admin');
      } else {
        console.error('Error logging in:');
        console.error('Login failed:', data.message);
        alert("username or password is wrong try again ");
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  return (
    <form onSubmit={handleLogin}>
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
        required
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        required
      />
      <button type="submit">Login</button>
    </form>
  );
};

export default Login;
