import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(localStorage.getItem('userId') || null);

  const saveUserId = (id) => {
    setUserId(id);
    localStorage.setItem('userId', id);
  };

  return (
    <UserContext.Provider value={{ userId, setUserId: saveUserId }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
