    import React, { useState, useEffect } from 'react';
    import axios from 'axios';
    import Modal from 'react-modal';
    import { useNavigate } from 'react-router-dom';
    import { Line } from 'react-chartjs-2';
    import { Chart as ChartJS, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend, Title } from 'chart.js';
    // import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

    ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend, Title);

    const AdminPage = () => {
        const [orders, setOrders] = useState({});
        const [filteredOrders, setFilteredOrders] = useState([]);
        const [products, setProducts] = useState([]);
        const [users, setUsers] = useState([]);
        const [statistics, setStatistics] = useState([]);
        const [newProduct, setNewProduct] = useState({
            name: '',
            description: '',
            price: '',
            stock: '',
            xs: false,
            s: false,
            M: false,
            L: false,
            XL: false,
            XXL: false,
            XXXL: false,
            images: [],
            colors: [{ name: '', color: '#000000' }],
            tags: ['']  // Initialize with one empty tag
        });
        
        
        const [selectedProduct, setSelectedProduct] = useState({
            name: '',
            description: '',
            price: '',
            stock: '',
            xs: false,
            s: false,
            M: false,
            L: false,
            XL: false,
            XXL: false,
            XXXL: false,
            images: [],
            colors: [{ name: '', color: '#000000' }],
            tags: ['']  // Initialize with one empty tag
        });
        const [newUser, setNewUser] = useState({ name: '', email: '', password: '' });
        const [chartData, setChartData] = useState({});
        const [modalIsOpen, setModalIsOpen] = useState(false);
        const [orderDetails, setOrderDetails] = useState(null);
        const [activeSection, setActiveSection] = useState("dashboard");
        const navigate = useNavigate();

        if (!localStorage.getItem('tokenAdmin')) {
            navigate('/adminlogin');
        }

        useEffect(() => {
            fetchOrders();
            fetchProducts();
            fetchUsers();
            fetchStatistics();
            fetchstat();   


        }, []);

        useEffect(() => {
            if (orders.length > 0) {
                prepareChartData();
                setFilteredOrders(orders); // Initialize with all orders
            }
            else{
                setFilteredOrders(orders);

            }
        }, [orders]);
        const fetchOrders = async () => {
            const token = localStorage.getItem('tokenAdmin');
            if (!token) {
                console.error('No token found in localStorage');
                return;
            }
        
            try {
                const response = await axios.post(
                    'https://api.ahollygrail.com/my-php-api/admin/getOrders.php',
                    new URLSearchParams({ token }),
                    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
                );
        
                if (response.data && response.data.data) {
                    
                    setOrders(response.data.data); // Set the state


                } else {
                    console.error('Unexpected response data structure:', response.data);
                }
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };
        


        const fetchstat = async () => {
            try {
                const response = await axios.post('https://api.ahollygrail.com/my-php-api/admin/checktoken.php', { token: localStorage.getItem('tokenAdmin')});
            if(response.data.message== 'token'){
                navigate('/adminlogin')
            }
            } catch (error) {
                console.error('Error deleting product:', error);
            }
        };
        
        

        const fetchProducts = async () => {

            try {
                const response = await fetch('https://api.ahollygrail.com/my-php-api/products/getProducts.php');
                const data = await response.json();
                const filteredItems = data.filter(item => item.del === "0");
                setProducts(filteredItems); 
            } catch (error) {
                console.error("Error fetching items:", error);
            }
        };

        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://api.ahollygrail.com/my-php-api/admin/getUsers.php');
                setUsers(response.data.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        

        const fetchStatistics = async () => {
            try {
                const response = await axios.get('https://api.ahollygrail.com/my-php-api/admin/getStatistics.php');
                setStatistics(response.data.data);
            } catch (error) {
                console.error('Error fetching statistics:', error);
            }
        };

        const prepareChartData = () => {
            if (!orders || orders.length === 0) {
                console.warn('No orders available to prepare chart data.');
                return;
            }

            const ordersPerDay = {};

            orders.forEach(order => {
                const date = new Date(order.created_at).toLocaleDateString();
                ordersPerDay[date] = (ordersPerDay[date] || 0) + 1;
            });

            const labels = Object.keys(ordersPerDay);
            const data = Object.values(ordersPerDay);


            setChartData({
                labels,
                datasets: [
                    {
                        label: 'Number of Orders per Day',
                        data,
                        borderColor: '#007bff',
                        backgroundColor: 'rgba(38, 143, 255, 0.2)',
                        fill: true,
                        tension: 0.1,
                    },
                ],
            });
        };


        const handleAddProduct = async () => {
            try {
            const formData = new FormData();
            formData.append('name', newProduct.name);
            formData.append('description', newProduct.description);
            formData.append('price', newProduct.price);
            formData.append('stock', newProduct.stock);
            formData.append('xs', newProduct.xs ? 1 : 0);
            formData.append('s', newProduct.s ? 1 : 0);
            formData.append('M', newProduct.M ? 1 : 0);
            formData.append('L', newProduct.L ? 1 : 0);
            formData.append('XL', newProduct.XL ? 1 : 0);
            formData.append('XXL', newProduct.XXL ? 1 : 0);
            formData.append('XXXL', newProduct.XXXL ? 1 : 0);
            formData.append('token', localStorage.getItem('tokenAdmin'));
        
            // Append colors
            newProduct.colors.forEach((color, index) => {
                formData.append(`colors[${index}][name]`, color.name);
                formData.append(`colors[${index}][color]`, color.color);
            });
        
            // Append tags
            newProduct.tags.forEach((tag, index) => {
                formData.append(`tags[${index}]`, tag);
            });
        
            // Append multiple images
            for (let i = 0; i < newProduct.images.length; i++) {
                formData.append('images[]', newProduct.images[i]);
            }
        
            const response = await axios.post('https://api.ahollygrail.com/my-php-api/admin/addProduct.php', formData, {
                headers: {
                'Content-Type': 'multipart/form-data',
                },
            });
            if(response.data.message == "token"){
                navigate('/adminlogin');
            }else{
                setActiveSection('products');
            }
            setNewProduct({
                name: '',
                description: '',
                price: '',
                stock: '',
                xs: false,
                s: false,
                M: false,
                L: false,
                XL: false,
                XXL: false,
                XXXL: false,
                images: [],
                colors: [{ name: '', color: '#000000' }],
                tags: ['']  // Reset tags
            });
            fetchProducts();
            } catch (error) {
            console.error('Error adding product:', error);
            }
        };
        
        

        const handleEditProduct = async () => {
            try {
                const formData = new FormData();
                formData.append('name', selectedProduct.name);
                formData.append('id', selectedProduct.id);
                formData.append('description', selectedProduct.description);
                formData.append('price', selectedProduct.price);
                formData.append('stock', selectedProduct.stock);
                formData.append('xs', selectedProduct.xs ? 1 : 0);
                formData.append('s', selectedProduct.s ? 1 : 0);
                formData.append('M', selectedProduct.M ? 1 : 0);
                formData.append('L', selectedProduct.L ? 1 : 0);
                formData.append('XL', selectedProduct.XL ? 1 : 0);
                formData.append('XXL', selectedProduct.XXL ? 1 : 0);
                formData.append('XXXL', selectedProduct.XXXL ? 1 : 0);
                formData.append('token', localStorage.getItem('tokenAdmin'));
            // Append colors
            selectedProduct.colors.forEach((color, index) => {
                formData.append(`colors[${index}][name]`, color.name);
                formData.append(`colors[${index}][color]`, color.color);
            });
        
            
                // Append tags
                selectedProduct.tags.forEach((tag, index) => {
                    formData.append(`tags[${index}]`, tag);
                });
            
                // Append multiple images
                if(selectedProduct.images){

                    for (let i = 0; i < selectedProduct.images.length; i++) {
                    formData.append('images[]', selectedProduct.images[i]);
                }
                }
                
            
                const response = await axios.post('https://api.ahollygrail.com/my-php-api/admin/editPorduct.php', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                });
                if(response.data.message == "token"){
                    navigate('/adminlogin');
                }else{
                    setActiveSection('products');
                }
                setSelectedProduct({
                    name: '',
                    description: '',
                    price: '',
                    stock: '',
                    xs: false,  
                    s: false,
                    M: false,
                    L: false,
                    XL: false,
                    XXL: false,
                    XXXL: false,
                    images: [],
                    colors: [{ name: '', color: '#000000' }],
                    tags: ['']  // Reset tags
                });
                fetchProducts();
                } catch (error) {
                console.error('Error adding product:', error);
                }
        };

        const handleDeleteProduct = async (productId) => {
            try {
                await axios.post('https://api.ahollygrail.com/my-php-api/products/deleteProduct.php', { product_id: productId ,token: localStorage.getItem('tokenAdmin')});
                fetchProducts();
            } catch (error) {
                console.error('Error deleting product:', error);
            }
        };

        const handleAddUser = async () => {
            try {
                const response = await axios.post('https://api.ahollygrail.com/my-php-api/admin/addUser.php', newUser);
                if(response.data.message == "token"){
                    navigate('/adminlogin');
                }else{
                    setActiveSection('users');
                }
                setNewUser({ name: '', email: '', password: '' });
                fetchUsers();
            } catch (error) {
                console.error('Error adding user:', error);
            }
        };

        const handleDeleteUser = async (userId) => {
            try {
                await axios.post('https://api.ahollygrail.com/my-php-api/user/deleteUser.php', { user_id: userId, token:localStorage.getItem('tokenAdmin')  });
                fetchUsers();
            } catch (error) {
                console.error('Error deleting user:', error);
            }
        };

        const handleUpdateOrderStatus = async (orderId, status) => {
            try {
                await axios.post('https://api.ahollygrail.com/my-php-api/admin/updateOrderStatus.php', { order_id: orderId, status, token:localStorage.getItem('tokenAdmin') });
                setModalIsOpen(false);
                fetchOrders();
            } catch (error) {
                console.error('Error updating order status:', error);
            }
        };

        const handleGetOrderInfo = async (orderId) => {
            try {
                const response = await axios.get(`https://api.ahollygrail.com/my-php-api/admin/getOrderInfo.php?order_id=${orderId}&token=${localStorage.getItem('tokenAdmin')}`);
                setOrderDetails(response.data.data);
                setModalIsOpen(true);
            } catch (error) {
                console.error('Error getting order info:', error);
            }
        };

        const closeModal = () => {
            setModalIsOpen(false);
            setOrderDetails(null);
        };

        const filterOrders = (status) => {
            if (status === 'all') {
                setFilteredOrders(orders);
            } else {
                setFilteredOrders(orders.filter(order => order.status === status));
            }
        };

        const [offer, setOffer] = useState('');
        const [message, setMessage] = useState('');
      
        const handleSubmitOffer = async (e) => {
          e.preventDefault();
      
          try {
            const response = await fetch('https://api.ahollygrail.com/my-php-api/admin/setOffer.php', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: new URLSearchParams({
                offer: offer,
              }),
            });
      
            const data = await response.json();
            setMessage(data.message);
          } catch (error) {
            setMessage('An error occurred: ' + error.message);
          }
        };
        return (
            
            <div className="container-fluid admin-page">
                <div className="row">
                

                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                            <li className="nav-item">
                            <button className={`nav-link ${activeSection === 'dashboard' ? 'active' : ''}`} onClick={() => setActiveSection('dashboard')}>Dashboard</button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link ${activeSection === 'products' ? 'active' : ''}`} onClick={() => setActiveSection('products')}>Products</button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link ${activeSection === 'addProduct' ? 'active' : ''}`} onClick={() => setActiveSection('addProduct')}>Add Product</button>
                            </li>
                            <li className="nav-item">
                            <button className={`nav-link ${activeSection === 'users' ? 'active' : ''}`} onClick={() => setActiveSection('users')}>Users</button>
                            </li>
                            <li className="nav-item">
                            <button className={`nav-link ${activeSection === 'addUser' ? 'active' : ''}`} onClick={() => setActiveSection('addUser')}>Add User</button>
                            </li>
                            <li className="nav-item">
                            <button className={`nav-link ${activeSection === 'orders' ? 'active' : ''}`} onClick={() => setActiveSection('orders')}>Orders</button>
                            </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                    <main >
                    {activeSection === 'dashboard' && (
                        <div>
                        <div className="container">
                            <div className="row">
                                <section className="col-lg-3 m-lg-3 col-12  card rounded-3 shadow">
                                    <h2>New Users</h2>
                                    <ul className="list-group mx-s-5 my-s-3">
                                        {users
                                            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                                            .slice(0, 1)
                                            .map((user) => (
                                                <li key={user.id} className="list-group-item d-flex justify-content-between align-items-center">
                                                    {user.name} - {user.email}
                                                </li>
                                            ))}
                                    </ul>
                                    <button className={"btn btn-light shadow rounded my-2"} onClick={() => setActiveSection('addUser')}>Add User</button>
                                    <button className={"btn btn-light shadow rounded my-2"} onClick={() => setActiveSection('users')}>All Users</button>
                                </section>
                                <section className="col-lg-3 col-12 m-lg-3 card rounded-3 shadow">
                                    <h2>Products</h2>
                                    <ul className="list-group ">
                                        {products.map((product) => (
                                            <li key={product.id} className="list-group-item d-flex justify-content-between align-items-center">
                                                {product.name} - ${product.price}
                                                
                                            </li>
                                        ))}
                                    </ul>
                                    <button className={"btn btn-light shadow rounded my-2"} onClick={() => setActiveSection('addProduct')}>Add Product</button>
                                    <button className={"btn btn-light shadow rounded my-2"} onClick={() => setActiveSection('products')}>All Products</button>
                                </section>
                                <section className="col-lg-3  m-lg-3 col-12  card rounded-3 shadow">
                                    <ul className="list-group mx-s-5 my-s-3">
                                        {filteredOrders?.length > 0 ? (
                                            filteredOrders.map((order) => (
                                                <li key={order.id} onClick={() => handleGetOrderInfo(order.id)} className="list-group-item btn btn-light shadow my-2">
                                                    Order #{order.id} - {order.status}
                                                </li>
                                            ))
                                        ) : (
                                            <p>No orders available</p>
                                        )}
                                    </ul>
                                    <button className={"btn btn-light shadow rounded my-2"} onClick={() => setActiveSection('orders')}>All Orders</button>
                                </section>
                            </div>

                        </div>
                        <div className="container rounded-3 shadow">
                                <h2>Update Offer for All Products</h2>
                                <form onSubmit={handleSubmitOffer}>
                                    <label>
                                    Offer Value:
                                    <input
                                        type="number"
                                        value={offer}
                                        onChange={(e) => setOffer(e.target.value)}
                                        required
                                    />
                                    </label>
                                    <button type="submit">Submit</button>
                                </form>
                                {message && <p>{message}</p>}
                            </div>
                        </div>
                        )}

                        {activeSection === 'products' && (
                            <section>
                                <h2>Products</h2>
                                <ul className="list-group">
                                    {products.map((product) => (
                                        <li key={product.id} className="list-group-item d-flex justify-content-between align-items-center">
                                            {product.name} - ${product.price} - ${product.size}
                                            <div>
                                                <button className="btn btn-warning btn-sm me-2" onClick={() => {
                                                    setActiveSection('editproducts');
                                                    setSelectedProduct({
                                                        id:product.id,
                                                        name: product.name,
                                                        description: product.description,
                                                        price: product.price,
                                                        stock: product.stock,
                                                        xs: parseInt(Object.entries(JSON.parse(product.size))[0][1]),
                                                        s:  parseInt(Object.entries(JSON.parse(product.size))[1][1]),
                                                        M:  parseInt(Object.entries(JSON.parse(product.size))[2][1]),
                                                        L:  parseInt(Object.entries(JSON.parse(product.size))[3][1]),
                                                        XL:  parseInt(Object.entries(JSON.parse(product.size))[4][1]),
                                                        XXL:  parseInt(Object.entries(JSON.parse(product.size))[5][1]),
                                                        XXXL: parseInt(Object.entries(JSON.parse(product.size))[6][1]),
                                                        colors: JSON.parse(product.color),
                                                        tags: Array.from(JSON.parse(product.tags))  // Initialize with one empty tagArray.from(JSON.parse(currentItem?.tags))[0]
                                                    });

                                                    }}>Edit</button>
                                                <button className="btn btn-danger btn-sm" onClick={() => handleDeleteProduct(product.id)}>Delete</button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <button className={"btn btn-light shadow rounded my-2"} onClick={() => setActiveSection('addProduct')}>Add Product</button>
                            </section>
                        )}

                        {selectedProduct &&activeSection === 'editproducts'&& (
                            <section>
                                <h2>Edit Product</h2>
                                <form>
                                    <div className="mb-3">
                                        <label className="form-label">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={selectedProduct.name}
                                            onChange={(e) => setSelectedProduct({ ...selectedProduct, name: e.target.value })}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Price</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={selectedProduct.price}
                                            onChange={(e) => setSelectedProduct({ ...selectedProduct, price: e.target.value })}
                                        />
                                    </div>
                                    <div className="mb-3">
                                <label htmlFor="description" className="form-label">Description</label>
                                <textarea
                                    className="form-control"
                                    id="description"
                                    value={selectedProduct.description}
                                    onChange={(e) => setSelectedProduct({ ...selectedProduct, description: e.target.value })}
                                />
                            </div>
                            
                            <div className="mb-3">
                                <label htmlFor="stock" className="form-label">Stock</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="stock"
                                    value={selectedProduct.stock}
                                    onChange={(e) => setSelectedProduct({ ...selectedProduct, stock: e.target.value })}
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="xs" className="form-label ps-4">XS</label>
                                <input
                                    type="checkbox"
                                    className="ms-1"
                                    id="xs"
                                    checked={selectedProduct.xs===1 ||selectedProduct.xs==="1" || selectedProduct.xs===true}
                                    onChange={(e) => setSelectedProduct({ ...selectedProduct, xs: e.target.checked===true? 1:0 })}
                                />
                                
                                <label htmlFor="s" className="form-label ps-4">S</label>
                                <input
                                    type="checkbox"
                                    className="ms-1"
                                    id="s"
                                    checked={selectedProduct.s===1 ||selectedProduct.s==="1" || selectedProduct.s===true}
                                    onChange={(e) => setSelectedProduct({ ...selectedProduct, s: e.target.checked===true? 1:0 })}
                                />
                                
                                <label htmlFor="M" className="form-label ps-4">M</label>
                                <input
                                    type="checkbox"
                                    className="ms-1"
                                    id="M"
                                    checked={selectedProduct.M===1 ||selectedProduct.M==="1" || selectedProduct.M===true}
                                    onChange={(e) => setSelectedProduct({ ...selectedProduct, M: e.target.checked===true? 1:0 })}
                                />
                                
                                <label htmlFor="L" className="form-label ps-4">L</label>
                                <input
                                    type="checkbox"
                                    className="ms-1"
                                    id="L"
                                    checked={selectedProduct.L===1 ||selectedProduct.L==="1" || selectedProduct.L===true}
                                    onChange={(e) => setSelectedProduct({ ...selectedProduct, L: e.target.checked===true? 1:0 })}
                                />
                                
                                <label htmlFor="XL" className="form-label ps-4">XL</label>
                                <input
                                    type="checkbox"
                                    className="ms-1"
                                    id="XL"
                                    checked={selectedProduct.XL===1 ||selectedProduct.XL==="1" || selectedProduct.XL===true}
                                    onChange={(e) => setSelectedProduct({ ...selectedProduct, XL: e.target.checked===true? 1:0 })}
                                />
                                
                                <label htmlFor="XXL" className="form-label ps-4">XXL</label>
                                <input
                                    type="checkbox"
                                    className="ms-1"
                                    id="XXL"
                                    checked={selectedProduct.XXL===1 ||selectedProduct.XXL==="1" || selectedProduct.XXL===true}
                                    onChange={(e) => setSelectedProduct({ ...selectedProduct, XXL: e.target.checked===true? 1:0 })}
                                />
                                
                                <label htmlFor="XXXL" className="form-label ps-4">XXXL</label>
                                <input
                                    type="checkbox"
                                    className="ms-1"
                                    id="XXXL"
                                    checked={selectedProduct.XXXL===1 ||selectedProduct.XXXL==="1" || selectedProduct.XXXL===true}
                                    onChange={(e) => setSelectedProduct({ ...selectedProduct, XXXL: e.target.checked===true? 1:0 })}
                                />
                            </div>
                            
                            
                            <div className="mb-3">
                                <label>Available Colors</label>
                                {/* JSON.parse(currentItem?.color).map((colorObj) */}
                                {selectedProduct.colors.map((colorItem,index) => (
                                    <div key={index} className="d-flex align-items-center mb-2">
                                        <input
                                            type="text"
                                            className="form-control me-2"
                                            placeholder="Color Name"
                                            value={colorItem['name']}
                                            onChange={(e) => {
                                                const updatedColors = [...selectedProduct.colors];
                                                updatedColors[index].name = e.target.value;
                                                setSelectedProduct({ ...selectedProduct, colors: updatedColors });
                                            }}
                                        />
                                        <input
                                            type="color"
                                            className="form-control-color"
                                            value={colorItem['color']}
                                            onChange={(e) => {
                                                const updatedColors  = [...selectedProduct.colors];
                                                updatedColors[index]['color'] = e.target.value;
                                                setSelectedProduct({ ...selectedProduct, colors: updatedColors });
                                            }}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-danger ms-2"
                                            onClick={() => {
                                                const updatedColors = selectedProduct.colors.filter((_, i) => i !== index);
                                                setSelectedProduct({ ...selectedProduct, colors: updatedColors });
                                            }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-secondary mt-2"
                                    onClick={() => {
                                        setSelectedProduct({
                                            ...selectedProduct,
                                            colors: [...selectedProduct.colors, { name: '', color: '#000000' }]
                                        });
                                    }}
                                >
                                    Add Color
                                </button>
                            </div>

                            <div className="mb-3">
                                <label>Tags</label>
                                {selectedProduct.tags.map((tag, index) => (
                                    <div key={index} className="d-flex align-items-center mb-2">
                                        <input
                                            type="text"
                                            className="form-control me-2"
                                            placeholder="Tag"
                                            value={tag}
                                            onChange={(e) => {
                                                const updatedTags = [...selectedProduct.tags];
                                                updatedTags[index] = e.target.value;
                                                setSelectedProduct({ ...selectedProduct, tags: updatedTags });
                                            }}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-danger ms-2"
                                            onClick={() => {
                                                const updatedTags = selectedProduct.tags.filter((_, i) => i !== index);
                                                setSelectedProduct({ ...selectedProduct, tags: updatedTags });
                                            }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-secondary mt-2"
                                    onClick={() => {
                                        setSelectedProduct({
                                            ...selectedProduct,
                                            tags: [...selectedProduct.tags, '']
                                        });
                                    }}
                                >
                                    Add Tag
                                </button>
                            </div>
                            
                            <div className="mb-3">
                                <label htmlFor="images" className="form-label">Images</label>
                                <input
                                type="file"
                                className="form-control"
                                id="images"
                                multiple
                                onChange={(e) => setSelectedProduct({ ...selectedProduct, images: e.target.files })}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">Add Product</button>

                                    <button type="button" className="btn btn-success" onClick={() => handleEditProduct()}>Save Changes</button>
                                    <button type="button" className="btn btn-secondary ms-2" onClick={() => setSelectedProduct(null)}>Cancel</button>
                                </form>
                            </section>
                        )}

                    {activeSection === 'addProduct' && (
                        <section>
                            <h2>Add Product</h2>
                            <form onSubmit={(e) => { e.preventDefault(); handleAddProduct(); }}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={newProduct.name}
                                    onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Description</label>
                                <textarea
                                    className="form-control"
                                    id="description"
                                    value={newProduct.description}
                                    onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="price" className="form-label">Price</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="price"
                                    value={newProduct.price}
                                    onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="stock" className="form-label">Stock</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="stock"
                                    value={newProduct.stock}
                                    onChange={(e) => setNewProduct({ ...newProduct, stock: e.target.value })}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="xs" className="form-label ps-4">XS</label>
                                <input
                                    type="checkbox"
                                    className="ms-1"
                                    id="xs"
                                    checked={newProduct.xs || false}
                                    onChange={(e) => setNewProduct({ ...newProduct, xs: e.target.checked })}
                                />
                                
                                <label htmlFor="s" className="form-label ps-4">S</label>
                                <input
                                    type="checkbox"
                                    className="ms-1"
                                    id="s"
                                    checked={newProduct.s || false}
                                    onChange={(e) => setNewProduct({ ...newProduct, s: e.target.checked })}
                                />
                                
                                <label htmlFor="M" className="form-label ps-4">M</label>
                                <input
                                    type="checkbox"
                                    className="ms-1"
                                    id="M"
                                    checked={newProduct.M || false}
                                    onChange={(e) => setNewProduct({ ...newProduct, M: e.target.checked })}
                                />
                                
                                <label htmlFor="L" className="form-label ps-4">L</label>
                                <input
                                    type="checkbox"
                                    className="ms-1"
                                    id="L"
                                    checked={newProduct.L || false}
                                    onChange={(e) => setNewProduct({ ...newProduct, L: e.target.checked })}
                                />
                                
                                <label htmlFor="XL" className="form-label ps-4">XL</label>
                                <input
                                    type="checkbox"
                                    className="ms-1"
                                    id="XL"
                                    checked={newProduct.XL || false}
                                    onChange={(e) => setNewProduct({ ...newProduct, XL: e.target.checked })}
                                />
                                
                                <label htmlFor="XXL" className="form-label ps-4">XXL</label>
                                <input
                                    type="checkbox"
                                    className="ms-1"
                                    id="XXL"
                                    checked={newProduct.XXL || false}
                                    onChange={(e) => setNewProduct({ ...newProduct, XXL: e.target.checked })}
                                />
                                
                                <label htmlFor="XXXL" className="form-label ps-4">XXXL</label>
                                <input
                                    type="checkbox"
                                    className="ms-1"
                                    id="XXXL"
                                    checked={newProduct.XXXL || false}
                                    onChange={(e) => setNewProduct({ ...newProduct, XXXL: e.target.checked })}
                                />
                            </div>
                            
                            <div className="mb-3">
                                <label>Available Colors</label>
                                {newProduct.colors.map((colorItem, index) => (
                                    <div key={index} className="d-flex align-items-center mb-2">
                                        <input
                                            type="text"
                                            className="form-control me-2"
                                            placeholder="Color Name"
                                            value={colorItem.name}
                                            onChange={(e) => {
                                                const updatedColors = [...newProduct.colors];
                                                updatedColors[index].name = e.target.value;
                                                setNewProduct({ ...newProduct, colors: updatedColors });
                                            }}
                                        />
                                        <input
                                            type="color"
                                            className="form-control-color"
                                            value={colorItem.color}
                                            onChange={(e) => {
                                                const updatedColors = [...newProduct.colors];
                                                updatedColors[index].color = e.target.value;
                                                setNewProduct({ ...newProduct, colors: updatedColors });
                                            }}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-danger ms-2"
                                            onClick={() => {
                                                const updatedColors = newProduct.colors.filter((_, i) => i !== index);
                                                setNewProduct({ ...newProduct, colors: updatedColors });
                                            }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-secondary mt-2"
                                    onClick={() => {
                                        setNewProduct({
                                            ...newProduct,
                                            colors: [...newProduct.colors, { name: '', color: '#000000' }]
                                        });
                                    }}
                                >
                                    Add Color
                                </button>
                            </div>

                            <div className="mb-3">
                                <label>Tags</label>
                                {newProduct.tags.map((tag, index) => (
                                    <div key={index} className="d-flex align-items-center mb-2">
                                        <input
                                            type="text"
                                            className="form-control me-2"
                                            placeholder="Tag"
                                            value={tag}
                                            onChange={(e) => {
                                                const updatedTags = [...newProduct.tags];
                                                updatedTags[index] = e.target.value;
                                                setNewProduct({ ...newProduct, tags: updatedTags });
                                            }}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-danger ms-2"
                                            onClick={() => {
                                                const updatedTags = newProduct.tags.filter((_, i) => i !== index);
                                                setNewProduct({ ...newProduct, tags: updatedTags });
                                            }}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-secondary mt-2"
                                    onClick={() => {
                                        setNewProduct({
                                            ...newProduct,
                                            tags: [...newProduct.tags, '']
                                        });
                                    }}
                                >
                                    Add Tag
                                </button>
                            </div>
                            
                            <div className="mb-3">
                                <label htmlFor="images" className="form-label">Images</label>
                                <input
                                type="file"
                                className="form-control"
                                id="images"
                                multiple
                                onChange={(e) => setNewProduct({ ...newProduct, images: e.target.files })}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">Add Product</button>
                        </form>

                        </section>
                    )}


                    {activeSection === 'users' && (
                        <section>
                            <h2>Users</h2>
                            <ul className="list-group">
                                {users.map((user) => (
                                    <li key={user.id} className="list-group-item d-flex justify-content-between align-items-center">
                                        {user.name} - {user.email}
                                        <button className="btn btn-danger btn-sm" onClick={() => handleDeleteUser(user.id)}>Delete</button>
                                    </li>
                                ))}
                            </ul>
                            <button className={"btn btn-light shadow rounded my-2"} onClick={() => setActiveSection('addUser')}>Add User</button>
                        </section>
                    )}

                    {activeSection === 'addUser' && (
                        <section>
                            <div className="mb-3">
                            <div className="d-flex justify-content-center mt-5 " >
                                <div className='shadow-lg card border-0 p-5'>
                                    <div className='' style={{width: "80vw"}}>
                                    <h2>Add User</h2>
                                    <div>
                                    <label>Username:</label>
                                    <input className="form-control mb-2" type="text" placeholder="Name" value={newUser.name} onChange={(e) => setNewUser({ ...newUser, name: e.target.value })} />
                                    </div>
                                    <div>
                                    <label>Email:</label>
                                    <input className="form-control mb-2" type="email" placeholder="Email" value={newUser.email} onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} />
                                    </div>
                                    <div>
                                    <label>Password:</label>
                                    <input className="form-control mb-2" type="password" placeholder="Password" value={newUser.password} onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} />
                                    </div>
                                    <button className="btn btn-primary" onClick={handleAddUser}>Add User</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </section>
                    )}

                    {activeSection === 'orders' && (
                        <section>
                            <h2>Orders</h2>
                            <ul className="list-group">
                            {console.log(filteredOrders.length > 0)}
                            {filteredOrders.length > 0 ? (
                            <div style={{minHeight: "80vh"}}>
                                <div className="mb-3">
                                    <button className="btn btn-secondary me-2" onClick={() => filterOrders('shipped')}>Shipped</button>
                                    <button className="btn btn-secondary me-2" onClick={() => filterOrders('pending')}>Pending</button>
                                    <button className="btn btn-secondary me-2" onClick={() => filterOrders('completed')}>Completed</button>
                                    <button className="btn btn-secondary" onClick={() => filterOrders('all')}>All</button>
                                </div>
                                    <div>
                                        <h3>Orders Over Time</h3>
                                        <div className="chart-container mb-4" style={{ position: 'relative', height: '400px' }}>
                                            <Line data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
                                        </div>
                                    </div>
                                    {filteredOrders.map((order) => (
                                        <li key={order.id} className="list-group-item d-flex justify-content-between align-items-center">
                                            Order #{order.id} - {order.status}
                                            <button className="btn btn-info btn-sm" onClick={() => handleGetOrderInfo(order.id)}>View Details</button>
                                        </li>
                                ))}
                                </div>


                            ) : (
                                <div style={{minHeight: "80vh"}}>
                                    <p>No orders available</p>
                                </div>
                                
                            )}
                            </ul>
                        </section>
                    )}
                </main>
            </div>

            {/* Order Details Modal */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Order Details"
                // className="react-modal-content"
                overlayClassName="react-modal-overlay"  
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxWidth: '600px',
                        padding: '20px',
                        position: "fixed"
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                }}
            >
                <h2>Order Details</h2>
                {orderDetails && (
                    <div className="order-details">
                        <p><strong>Order ID:</strong> {orderDetails[0].order_id}</p>
                        <p><strong>User ID:</strong> {orderDetails[0].user_id}</p>
                        <p><strong>Total:</strong> ${orderDetails[0].total}</p>
                        <p><strong>Status:</strong> {orderDetails[0].status}</p>
                        <h3>Items</h3>
                        <ul>
                        {orderDetails?.length > 0 ? (
                                orderDetails.map((item, index) => (
                                    <li key={index}>
                                        <p><strong>Product ID:</strong> {item.product_id}</p>
                                        <p><strong>Quantity:</strong> {item.quantity}</p>
                                        <p><strong>Price:</strong> ${item.price}</p>
                                    </li>
                                ))
                            ) : (
                                <p>No order details available</p>
                            )}

                        </ul>
                        <div className="mt-3">
                            <button className="btn btn-success me-2" onClick={() => handleUpdateOrderStatus(orderDetails[0].id, 'completed')}>Mark as Completed</button>
                            <button className="btn btn-warning me-2" onClick={() => handleUpdateOrderStatus(orderDetails[0].id, 'shipped')}>Mark as Shipped</button>
                            <button className="btn btn-secondary me-2" onClick={() => handleUpdateOrderStatus(orderDetails[0].id, 'pending')}>Mark as Pending</button>
                        </div>
                        <button className="btn btn-danger mt-3" onClick={closeModal}>Close</button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default AdminPage;