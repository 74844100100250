import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence, color } from "framer-motion";
import { useUser } from '../UserContext';
import { json, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

export const Main = () => {
  const { userId } = useUser();
  const [items, setItems] = useState([]);
  const [cartStatus, setCartStatus] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [offer, setOffer] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);
  const expandedRef = useRef(null);
  const navigate = useNavigate();
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Function to check authentication state
    const checkAuthStatus = () => {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      setIsAuthenticated(!!token && !!userId);
    };

    // Check authentication status on component mount
    checkAuthStatus();

    // Listen for changes in localStorage
    window.addEventListener('storage', checkAuthStatus);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('storage', checkAuthStatus);
    };
  }, []);
  useEffect(() => {
    const fetchProductsAndRecommendations = async () => {
      try {
        // Fetch recommendations
        const recommendationResponse = await fetch('https://api.ahollygrail.com/my-php-api/products/recommendation.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_id: userId }), // Replace with dynamic user_id
        });
        
        // Check if the response is JSON
        const contentType = recommendationResponse.headers.get('content-type');
        let recommendationData;
  
        if (contentType && contentType.includes('application/json')) {
          recommendationData = await recommendationResponse.json();
          if (recommendationData === "ID") {
            console.log('Recommendation response is "ID", fetching items only');
            recommendationData = []; // Empty array if response is "ID"
          }
        } else {
          // Handle non-JSON responses
          const textResponse = await recommendationResponse.text();
          if (textResponse === "ID") {
            console.log('Recommendation response is "ID", fetching items only');
            recommendationData = []; // Empty array if response is "ID"
          } else {
            console.error('Unexpected response format');
            return; // Exit early if unexpected format
          }
        }
  
        // Fetch regular products
        const itemsResponse = await fetch('https://api.ahollygrail.com/my-php-api/products/getProducts.php');
        const allItems = await itemsResponse.json();
        const filteredItems = allItems.filter(item => item.del === "0");
  
        // Combine and deduplicate products based on id
        const combinedItems = [...recommendationData, ...filteredItems];
        const uniqueItemsMap = new Map();
  
        combinedItems.forEach(item => {
          // Add or update item in the map
          uniqueItemsMap.set(item.id, item);
        });
  
        // Convert map values to array
        const uniqueItems = Array.from(uniqueItemsMap.values());
  
        // Set state with unique items
        setItems(uniqueItems);
        setOffer(filteredItems[0].offer)
        // Log items for debugging

      } catch (error) {
        console.error('Error fetching products and recommendations:', error);
      }
    };
  
    fetchProductsAndRecommendations();
  }, []);
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (expandedRef.current && !expandedRef.current.contains(event.target)) {
        setSelectedId(null);
      }
    };

    if (selectedId) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedId]);

  const checkCartStatus = async (productId) => {
    if (!userId) return;

    try {
      const response = await fetch('https://api.ahollygrail.com/my-php-api/cart/isInCart.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId, token: localStorage.getItem('token'), product_id: productId }),
      });

      const data = await response.json();
      if (data.status === 'success') {
        setCartStatus(prevStatus => ({ ...prevStatus, [productId]: data.isInCart }));
      } else {
        console.error("Error checking cart status:", data.message);
      }
    } catch (error) {
      console.error("Error checking cart status:", error);
    }
  };

  useEffect(() => {
    if (items.length > 0 && userId) {
      items.forEach(item => checkCartStatus(item.id));
    }
  }, [items, userId]);

  const addToCart = async () => {
    if (!userId) {
      alert('You need to log in to add items to the cart.');
      return;
    }
  
    if (!selectedSize || !selectedColor) {
      alert('Please select both a size and a color.');
      return;
    }
  
    try {
      const response = await fetch('https://api.ahollygrail.com/my-php-api/cart/addToCart.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          token: localStorage.getItem('token'),
          product_id: selectedId,
          Color: selectedColor,
          size: selectedSize,
          quantity: quantity,
        }),
      });
  
      const data = await response.json();
      if (data.status === "success") {
        setCartStatus(prevStatus => ({ ...prevStatus, [selectedId]: true }));
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Failed to add to cart:", error);
      navigate('/login');
    }
  };
  

  const removeFromCart = async () => {
    if (!userId) {
      alert('You need to log in to remove items from the cart.');
      return;
    }

    try {
      const response = await fetch('https://api.ahollygrail.com/my-php-api/cart/removeFromCartmain.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId,
          token: localStorage.getItem('token'),
          product_id: selectedId,
        }),
      });

      const data = await response.json();
      if (data.status === "success") {
        setCartStatus(prevStatus => ({ ...prevStatus, [selectedId]: false }));
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Failed to remove from cart:", error);
      alert("Failed to remove from cart.");
    }
  };

  const currentItem = items.find(item => item.id === selectedId);
  const images = currentItem?.image || []; // Ensure images is defined

  return (
  
    <div>  
      {!userId ? (
      <div class="marquee-container">
        <marquee direction="right" behavior="alternate">
          <p><a href="/register">Register</a> now and enjoy {offer}% off</p>
        </marquee>
      </div>
      ):(null)}

    <img src={require('../images/banner.jpeg')} style={{maxHeight: "70vh"}} alt="Banner" className="banner" />
      <div className={`${selectedId ? 'blurred' : ''}`} style={{backgroundColor:"#ededed"}}>
        <link rel="stylesheet" href="https://unpkg.com/98.css"></link>
        <div className="container" ref={containerRef} style={{position: 'relative',paddingTop: '50px',}}>
          {items.map((item, index) => (
            <motion.div key={item.id} layoutId={item.id.toString()}>
              <motion.div
                className={`item ${index % 2 === 0 ? 'top' : 'bottom'}`}
                style={{
                  backgroundColor: "#f0f0f0",
                  cursor: "pointer",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                  position: "relative"
                }}
                drag={!selectedId}
                dragConstraints={containerRef}
              >
                <div class="title-bar">
                  <div class="title-bar-text">{item.name}</div>
                  <div class="title-bar-controls">
                    <button aria-label="Minimize"></button>
                    <button onClick={() => setSelectedId(item.id)} aria-label="Maximize"></button>
                    <button aria-label="Close"></button>
                  </div>
                </div>
                <motion.img src={`https://api.ahollygrail.com/my-php-api/${item.image[0]}`} alt={item.name} style={{ width: "100%" }} />
              </motion.div>
            </motion.div>
          ))}
          <AnimatePresence>
            {selectedId && (
              <motion.div
                layoutId={selectedId.toString()}
                className="expandedItem"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.5 }}
                transition={{ duration: 0.5 }}
                style={{
                  position: "fixed",
                  top: "10%",
                  left: "10%",
                  width: "80%",
                  maxHeight: "80vh",
                  borderRadius: "20px",
                  backgroundColor: "#fff",
                }}
                drag={false}
                ref={expandedRef}
              >
              <div class="title-bar">
                <div class="title-bar-text">{currentItem?.name}</div>
                <div class="title-bar-controls">
                  <button onClick={() => setSelectedId(null)} aria-label="Minimize"></button>
                  <button onClick={() => setSelectedId(null)} aria-label="Restore"></button>
                  <button onClick={() => setSelectedId(null)} aria-label="Close"></button>
                </div>
              </div>
              <motion.div style={{  
                  width: '100%'              ,  
                  top: "10%",
                  left: "10%",
                  height: "80vh",
                  borderRadius: "20px",
                  backgroundColor: "#fff",
                  overflow: "auto"}}>
                {images.length > 0 && (
                  <div className="slider-container" style={{ width: '100%'}}>
                    <div className="slider" style={{ width: '100%'}}>
                      <motion.img
                        key={currentIndex}
                        src={`https://api.ahollygrail.com/my-php-api${images[currentIndex]}`}
                        alt={`Slide ${currentIndex}`}
                        style={{width: '50%',  objectFit: 'cover', borderRadius: '10px' }}
                      />  
                    </div>
                    <button
                    className="btn btn-light"
                      onClick={() => setCurrentIndex(prevIndex => (prevIndex - 1 + images.length) % images.length)}
                      style={{
                        position: 'relative',
                        left: '10px',
                        top: '50%',
                      }}
                    >
                      <i className="fas fa-chevron-left"></i>
                    </button>
                    <button 
                    className="btn btn-light"
                      onClick={() => setCurrentIndex(prevIndex => (prevIndex + 1) % images.length)}
                      style={{
                        position: 'relative',
                        left: '10px',
                        top: '50%',
                      }}
                    >
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>
                )}
                <motion.h6>{currentItem?.description}</motion.h6>
                <motion.h2>{currentItem?.name}</motion.h2>
                <div className="card" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <motion.h3 style={{ textDecoration: 'line-through', opacity: 0.5, color: 'gray', fontStyle: 'italic' }}>
                  {currentItem?.price}$
                </motion.h3>
                      <motion.h3>{currentItem?.price - (currentItem?.price * offer / 100)}$</motion.h3>
                      <motion.span>{offer}% off</motion.span>
                  </div>
                </div>
 

                {/* <motion.h3>{ Array.from(JSON.parse(currentItem?.tags))[0]} tag</motion.h3> */}
                <motion.div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div className="d-flex justify-content-center">
                    <span  style={{color:"#808080",fontSize:"18px"}}>Select color:</span>
                    <div className="d-flex justify-content-center">
                      {JSON.parse(currentItem?.color).map((colorObj) => {
                        const color = colorObj.color;
                        return (
                          <button
                            key={color}
                            style={{
                              appearance: "none",
                              width:  "30px",
                              height: "30px",
                              border: "2px solid #ccc",
                              borderRadius: "5%",
                              cursor: "pointer",
                              outline: "none",
                              boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                              transition: "transform 0.2s",
                              backgroundColor: selectedColor === color ? color : "transparent",
                              borderColor: color,
                              margin: "0 5px",
                            }}
                            className={selectedColor === color ? "shadow" : ""}
                            onClick={() => setSelectedColor(color)}
                          ></button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                  <span style={{color:"#808080",fontSize:"18px"}}>Select size:</span>
                    <div className="d-flex justify-content-center">
                      {Object.entries(JSON.parse(currentItem?.size))
                        .filter((item) => item[1] === "1")
                        .map((size) => (
                          <button
                            key={size[0]}
                            className="d-inline"
                            style={{
                              appearance: "none",
                              width: "30px",
                              height: "30px",
                              border: "2px solid #ccc",
                              borderRadius: "50%",
                              cursor: "pointer",
                              outline: "none",
                              boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                              transition: "transform 0.2s",
                              backgroundColor: "#ffffff",
                              borderColor: selectedSize === size[0] ? "#4CAF50" : "#ccc",
                              margin: "0 5px",
                            }}
                            onClick={() => setSelectedSize(size[0])}
                          >
                            {size[0]}
                          </button>
                        ))}
                    </div>
                  </div>
                  <span>{currentItem?.stock} items remaining</span>
                </motion.div> 

                <input
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
                  min="1"
                  style={{
                    width:"10%",
                    margin: "10px 0",
                    padding: "5px",
                    borderRadius: "5px",
                    border: "1px solid #ccc"
                  }}
                />
                {!cartStatus[selectedId] && (
                  <motion.button
                    onClick={addToCart}
                    className={"xp-button"}
                  >
                    Add to cart
                  </motion.button>
                )}
                {cartStatus[selectedId] && (
                  <motion.button
                    onClick={removeFromCart}
                    className={"xp-button"}
                  >
                    Remove from cart
                  </motion.button>
                )}
                <motion.button
                  onClick={() => setSelectedId(null)}
                  className={"xp-button"}
                >
                  Close
                </motion.button>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};
export default Main;