import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const VerifyEmail = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || '';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch('https://api.ahollygrail.com/my-php-api/auth/verify.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, code }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        navigate('/login'); // Redirect to login page after successful verification
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError('Verification failed. Please try again later.');
      console.error('Error:', err);
    }
  };

  const handleResend = async () => {
    setError('');

    try {
      const response = await fetch('https://api.ahollygrail.com/my-php-api/auth/resend-code.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (data.status !== 'success') {
        setError(data.message);
      }
    } catch (err) {
      setError('Failed to resend verification code. Please try again later.');
      console.error('Error:', err);
    }
  };

  return (
    <div>
      <h1>Verify Your Email</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Verification Code:</label>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </div>
        <button type="submit">Verify</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={handleResend}>Resend Code</button>
    </div>
  );
};

export default VerifyEmail;
