import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import  { useState } from 'react';
import { useUser } from '../UserContext';
const Logout = ({ onLogout }) => {
  const navigate = useNavigate();
  const [isRef, setIsRef] = useState(null);
  const { setUserId } = useUser();
  useEffect(() => {
    // Clear the localStorage items
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    setUserId(null);
    // Optionally, you can call a callback to update the app state
    onLogout();


    // Redirect to the home page after logging out
    console.log(isRef);
    // if(isRef){
    //     navigate(0); 
    //     setIsRef(false);
    // }
    navigate('/ ')
  }, [navigate, onLogout]);

  return null; // No UI needed for the logout component
};

export default Logout;
