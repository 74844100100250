import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import { useUser } from './UserContext';
import CartPage from './pages/CartPage';
import Recommendations from './pages/recommendations';
import CheckoutPage from './pages/CheckoutPage';
import AdminPage from './pages/admin/AdminPage';  
import AdminLogin from './pages/admin/AdminLogin';
import EditProductPage from './pages/admin/EditProductPage';
import AdminRegester from './pages/admin/AdminRegester';
import AboutPage from './pages/AboutPage';
import MyOrdersPage from './pages/myOrders';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Login from './components/Login';
import Logout from './components/Logout';
import Register from './components/Register';
import VerifyEmail from './components/VerifyEmail';
import { Link } from 'react-router-dom';

function App() {
  const [user, setUser] = useState(null);
  const { userId } = useUser(); // Use the context to get the user ID



  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = (user) => {
    setIsAuthenticated(true);
    localStorage.setItem('token', user.token);
    localStorage.setItem('userId', user.userId);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
  };
  
  return (
    <Router>
      <Navbar isAuthenticated={isAuthenticated} style={{backgroundColor: "#ededed"}} />
      <div style={{minHeight: "80vh"}}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/recommendations" element={<Recommendations />} />
          <Route path="/orders" element={<MyOrdersPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/logout" element={<Logout onLogout={handleLogout} />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/adminregister" element={<AdminRegester />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/adminlogin" element={<AdminLogin onLogin={handleLogin} />} />
          <Route path="/about" element={<AboutPage />} />

          <Route path="/editproduct/:productId" element={<EditProductPage />} />
        </Routes>
        </div>
      <Footer />
    </Router>

  );
}

export default App;
