import React from 'react';
import Main from '../components/Main'; // Import Main

function HomePage() {
  return (
    <Main />
  );
}

export default HomePage;
