// AboutUs.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const AboutUs = () => {
  return (
    <div style={{backgroundColor:"#cae3f9"}}>
      <img src={require('../images/aboutbanner.jpeg')} style={{maxHeight: "70vh"}} alt="Banner" className="banner" />
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
          {/* Header */}
          <div className="mb-4">
            <h1 className="display-4">About Us</h1>
            <p className="lead">Learn more about our company.</p>
          </div>
          <div>
            <p>
              Welcome to our company! We are committed to delivering high-quality products and exceptional service to our customers.
              With a passion for innovation and a dedication to excellence, we strive to make a positive impact in our industry and beyond.
            </p>
            <p>
              Our journey began with a simple idea: to provide products that not only meet but exceed customer expectations.
              Today, we continue to uphold this principle, ensuring that every product we offer is crafted with care and precision.
            </p>
            <p>
              Thank you for visiting our page. We look forward to serving you and meeting your needs.
            </p>
          </div>
        </div>
    </div>
    </div>

  );
};

export default AboutUs;
