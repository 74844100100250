import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import '../components/css/CheckoutPage.css';

const CheckoutPage = () => {
  const { userId } = useUser();
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [street, setStreet] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleCheckout = async () => {
    if (!userId || !country || !region || !city || !street || !postalCode) return;

    setLoading(true);

    try {
      const response = await fetch('https://api.ahollygrail.com/my-php-api/checkout/processCheckout.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: userId, 
          address: { country, region, city, street, postalCode },
          token: localStorage.getItem('token') 
        }),
        credentials: 'include',
      });
      const result = await response.json();
      if (result.status === 'success') {

        navigate('/recommendations');
      } else {
        alert('Error: ' + result.message);
        navigate('/login');
      }
    } catch (error) {
      alert('Error processing checkout: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="checkout-container  mt-5">
      <h1>Checkout</h1>
      <div className="checkout-info">
        <h2>Delivery Address</h2>
        <input
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          placeholder="Country"
        />
        <input
          value={region}
          onChange={(e) => setRegion(e.target.value)}
          placeholder="Region"
        />
        <input
          value={city}
          onChange={(e) => setCity(e.target.value)}
          placeholder="City"
        />
        <input
          value={street}
          onChange={(e) => setStreet(e.target.value)}
          placeholder="Street"
        />
        <input
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
          placeholder="Postal Code"
        />
      </div>

      <button onClick={handleCheckout} disabled={loading}>
        {loading ? 'Processing...' : 'Place Order'}
      </button>
    </div>
  );
};

export default CheckoutPage;
