// EditProductPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const EditProductPage = () => {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [images, setImages] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the product details based on productId
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`https://api.ahollygrail.com/my-php-api/products/getProduct.php?id=${productId}`);
                const data = response.data;
                setProduct(data);
                setName(data.name);
                setDescription(data.description);
                setPrice(data.price);
            } catch (error) {
                console.error('Error fetching product:', error);
            }
        };

        fetchProduct();
    }, [productId]);

    const handleEditProduct = async () => {
        try {
            const formData = new FormData();
            formData.append('id', productId);
            formData.append('name', name);
            formData.append('description', description);
            formData.append('price', price);
            formData.append('token', localStorage.getItem('tokenAdmin'));

            // Append multiple images if changed
            if (images) {
                for (let i = 0; i < images.length; i++) {
                    formData.append('images[]', images[i]);
                }
            }

            await axios.post('https://api.ahollygrail.com/my-php-api/products/editProduct.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            navigate('/admin'); // Navigate back to the admin page after editing
        } catch (error) {
            console.error('Error editing product:', error);
        }
    };

    return (
        <div>
            <h2>Edit Product</h2>
            {product ? (
                <form onSubmit={(e) => { e.preventDefault(); handleEditProduct(); }}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea
                            className="form-control"
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="price" className="form-label">Price</label>
                        <input
                            type="number"
                            className="form-control"
                            id="price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="images" className="form-label">Images</label>
                        <input
                            type="file"
                            className="form-control"
                            id="images"
                            multiple
                            onChange={(e) => setImages(e.target.files)}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">Save Changes</button>
                </form>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default EditProductPage;
