import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const Recommendations = () => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [cartStatus, setCartStatus] = useState({}); // Track cart status for each product
    const navigate = useNavigate();
    console.log(localStorage.getItem('userId') == null);
    if (localStorage.getItem('userId') == null) navigate('/login');
    useEffect(() => {
        // Fetch the recommended products from your PHP API
        const fetchRecommendations = async () => {
            try {
                const response = await axios.post('https://api.ahollygrail.com/my-php-api/products/recommendation.php', {
                    user_id: localStorage.getItem('userId') // Fetch user ID from localStorage or context
                });
                setProducts(response.data);
            } catch (error) {
                console.error("Error fetching recommendations:", error);
            }
        };

        fetchRecommendations();
    }, []);

    const handleCardClick = (product) => {
        setSelectedProduct(product);
        setCurrentIndex(0); // Reset index when selecting a new product
    };

    const handleCloseDetails = () => {
        setSelectedProduct(null);
    };

    const addToCart = () => {
        // Add product to cart logic here
        setCartStatus(prevStatus => ({ ...prevStatus, [selectedProduct.id]: true }));
    };

    const removeFromCart = () => {
        // Remove product from cart logic here
        setCartStatus(prevStatus => ({ ...prevStatus, [selectedProduct.id]: false }));
    };

    return (
        <div className="container my-5">
            <h2 className="text-center mb-4">We Also Recommend</h2>
            <div className="row">
            {localStorage.getItem('userId') == null ? (
                null
            ):
            (products.map(product => (
                <div key={product.id} className="col-md-4 mb-4">
                    <div className="card h-100 recommendation-card" onClick={() => handleCardClick(product)}>
                        <img
                            src={`https://api.ahollygrail.com/my-php-api${product.image[0]}`}
                            className="card-img-top"
                            alt={product.name}
                        />
                        <div className="card-body">
                            <h5 className="card-title">{product.name}</h5>
                            <p className="card-text">{product.description}</p>
                            <p className="price">${product.price}</p>
                        </div>
                    </div>
                </div>
            )))}
            </div>

            {selectedProduct && (
                <div className="modal-overlay" onClick={handleCloseDetails}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <button className="btn btn-danger close-btn" onClick={handleCloseDetails}>Close</button>
                        <h2>{selectedProduct.name}</h2>
                        <img
                            src={`https://api.ahollygrail.com/my-php-api${selectedProduct.image[currentIndex]}`}
                            className="modal-img"
                            alt={selectedProduct.name}
                        />
                        <div className="slider-container">
                            <button
                                className="btn btn-light"
                                onClick={() => setCurrentIndex(prevIndex => (prevIndex - 1 +selectedProduct.image).length % selectedProduct.image.length)}
                            >
                                <i className="fas fa-chevron-left"></i>
                            </button>
                            <button 
                                className="btn btn-light"
                                onClick={() => setCurrentIndex(prevIndex => (prevIndex + 1) % selectedProduct.image.length)}
                            >
                                <i className="fas fa-chevron-right"></i>
                            </button>
                        </div>
                        <p>{selectedProduct.description}</p>
                        <h3 className="price">
                            <span style={{ textDecoration: 'line-through', opacity: 0.5, color: 'gray', fontStyle: 'italic' }}>
                                ${selectedProduct.price}
                            </span>
                            ${selectedProduct.price - (selectedProduct.price * 10 / 100)} {/* Assuming a fixed 10% discount */}
                        </h3>
                        <span>{10}% off</span> {/* Assuming a fixed 10% discount */}
                        <div className="color-selection">
                            <span>Select color:</span>
                            <div>
                                {JSON.parse(selectedProduct.color).map((colorObj) => {
                                    const color = colorObj.color;
                                    return (
                                        <button
                                            key={color}
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                border: "2px solid #ccc",
                                                borderRadius: "5%",
                                                cursor: "pointer",
                                                backgroundColor: selectedColor === color ? color : "transparent",
                                                borderColor: color,
                                                margin: "0 5px",
                                            }}
                                            onClick={() => setSelectedColor(color)}
                                        ></button>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="size-selection">
                            <span>Select size:</span>
                            <div>
                                {Object.entries(JSON.parse(selectedProduct.size))
                                    .filter((item) => item[1] === "1")
                                    .map((size) => (
                                        <button
                                            key={size[0]}
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                border: "2px solid #ccc",
                                                borderRadius: "50%",
                                                cursor: "pointer",
                                                backgroundColor: selectedSize === size[0] ? "#4CAF50" : "#ffffff",
                                                borderColor: selectedSize === size[0] ? "#4CAF50" : "#ccc",
                                                margin: "0 5px",
                                            }}
                                            onClick={() => setSelectedSize(size[0])}
                                        >
                                            {size[0]}
                                        </button>
                                    ))}
                            </div>
                        </div>
                        <span>{selectedProduct.stock} items remaining</span>
                        <input
                            type="number"
                            value={quantity}
                            onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
                            min="1"
                            style={{
                                width: "10%",
                                margin: "10px 0",
                                padding: "5px",
                                borderRadius: "5px",
                                border: "1px solid #ccc"
                            }}
                        />
                        {!cartStatus[selectedProduct.id] && (
                            <button
                                onClick={addToCart}
                                className="btn btn-primary"
                            >
                                Add to cart
                            </button>
                        )}
                        {cartStatus[selectedProduct.id] && (
                            <button
                                onClick={removeFromCart}
                                className="btn btn-secondary"
                            >
                                Remove from cart
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Recommendations;
