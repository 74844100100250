
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import '../components/css/CartPage.css';
import { Link } from "react-router-dom";


const CartPage = () => {
  const [offer, setOffer] = useState(null);
  const { userId } = useUser();
  const [cartItems, setCartItems] = useState([]);
  const [editedQuantities, setEditedQuantities] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId) navigate('/login');

    const fetchCartItems = async () => {
      try {
        const response = await fetch(`https://api.ahollygrail.com/my-php-api/cart/getCart.php?user_id=${userId}&token=${localStorage.getItem('token')}`, {
          credentials: 'include',
        });
        const data = await response.json();
        console.log(data.status);
        console.log(data);
        if (data.message === "token") {
          // alert("Item added to cart!");
          navigate('/login');
          // setSelectedId(null);
        }
        setCartItems(data);
      } catch (error) {
        console.error('Error fetching cart items:', error);
      }
      
    };

    fetchCartItems();
  }, [userId]);

  const handleRemoveItem = async (productId) => {
    try {
      const response = await fetch('https://api.ahollygrail.com/my-php-api/cart/removeFromCart.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId, product_id: productId , token:localStorage.getItem('token')}),
        credentials: 'include',
      });
      const result = await response.json();
      if (result.status === 'success') {
        setCartItems((prevItems) => prevItems.filter((item) => item.id !== productId));
      } else {
        console.error('Failed to remove item:', result.message);
      }
    } catch (error) {
      console.error('Failed to remove item:', error);
    }
  };

  const handleUpdateQuantity = async (productId, newQuantity) => {
    try {
      const response = await fetch('https://api.ahollygrail.com/my-php-api/cart/updateQuantity.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId, product_id: productId, quantity: newQuantity }),
        credentials: 'include',
      });
      const result = await response.json();
      if (result.status === 'success') {
        setCartItems((prevItems) =>
          prevItems.map((item) =>
            item.id === productId ? { ...item, quantity: newQuantity } : item
          )
        );
        setEditedQuantities((prev) => ({ ...prev, [productId]: false }));
      } else {
        console.error('Failed to update quantity:', result.message);
      }
    } catch (error) {
      console.error('Failed to update quantity:', error);
    }
  };

  const handleQuantityChange = (productId, newQuantity) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === productId ? { ...item, quantity: newQuantity } : item
      )
    );
    setEditedQuantities((prev) => ({ ...prev, [productId]: true }));
  };

  useEffect(() => {
    const fetchProductsAndRecommendations = async () => {
      try {
        const itemsResponse = await fetch('https://api.ahollygrail.com/my-php-api/products/getProducts.php');
        const allItems = await itemsResponse.json();
        const filteredItems = allItems.filter(item => item.del === "0");
  
        setOffer(filteredItems[0].offer)
        // Log items for debugging
      } catch (error) {
        console.error('Error fetching products and recommendations:', error);
      }
    };
  
    fetchProductsAndRecommendations();
  }, []);
  const calculateTotalPrice = () => {
    const total = cartItems.reduce((total, item) => total + parseFloat(item.price) * item.quantity, 0).toFixed(2);

    return (total - (total * offer/100));
  };

  const handleCheckout = () => {
    navigate('/checkout');
  };



  return (
    <div className="cart-container mt-5">
      <h1 className="cart-title">Your Cart</h1>

      {cartItems.length === 0 ? (
      <div>
        <p>Your cart is empty.</p>
      </div>
      ) : (
      <ul className="cart-list">
        {cartItems.map((item) => (
                        <div>
          <li key={item.id} className="cart-item">
            <img src={`https://api.ahollygrail.com/my-php-api/${item.image[0]}`} alt={item.name} />
            <div className="cart-item-info">
              <span>{item.name}</span>
              <span>${parseFloat(item.price).toFixed(2)} each</span>
              <span>Total: ${(parseFloat(item.price) * item.quantity).toFixed(2) - (parseFloat(item.price) * item.quantity).toFixed(2)* offer / 100}</span>
              <span>{offer}% off </span>
            </div>
            <div className="cart-item-actions">
              <input
                type="number"
                value={item.quantity}
                onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value, 10))}
                min="1"
              />
              {editedQuantities[item.id] && (
                <button onClick={() => handleUpdateQuantity(item.id, item.quantity)}>Confirm</button>
              )}
              <button className="remove" onClick={() => handleRemoveItem(item.id)}>Remove</button>
            </div>
          </li>
                <div className="cart-total">
                <h2>Total Bill: ${calculateTotalPrice()}</h2>
                <button onClick={handleCheckout} className="checkout-button">Checkout</button>
                </div>
          </div>
        ))}
      </ul>
      )}

      <Link to="/">
        <button className="checkout-button">Continue Shopping</button>
      </Link>
    </div>
  );
};

export default CartPage;
