import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const UserOrders = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      if (!userId || !token) {
        setError('User ID or token not found in local storage.');
        return;
      }

      try {
        const response = await fetch('https://api.ahollygrail.com/my-php-api/products/getOrders.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: userId, token }),
        });

        if (!response.ok) throw new Error('Failed to fetch orders.');

        const data = await response.json();

        setOrders(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError(error.message);
      }
    };

    fetchOrders();
  }, []);

  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className="container mt-5">
      
      {orders.length === 0 ? (
        <div className="alert alert-info text-center">No orders found.</div>
      ) : (
        <div className="row">
            <h2 className="text-center mb-4">your Orders</h2>
          {orders.map(({ id, total, address, status, created_at, items }) => (
            <div key={id} className="col-md-12 mb-4">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h5 className="card-title">Order ID: {id}</h5>
                  <p className="card-text">Total: ${total}</p>
                  <p className="card-text">Address: {address}</p>
                  <p className="card-text">Status: {status}</p>
                  <p className="card-text">Created At: {new Date(created_at).toLocaleString()}</p>
                  <h6>Order Items:</h6>
                  <ul className="list-group">
                    {items?.map(({ product_id, product_name, quantity, price }) => (
                      <li key={product_id} className="list-group-item">
                        <strong>{product_name}</strong> - Quantity: {quantity}, Price: ${price}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserOrders;
